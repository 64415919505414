<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="关键字搜索"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">用户积分数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="用户手机号码" align="center" prop="mobile">
        <template slot-scope="scope">
          <span>{{ scope.row.user.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户微信昵称" align="center" prop="wx_name">
        <template slot-scope="scope">
          <span>{{ scope.row.user.wx_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="可用积分"
        align="center"
        prop="free_amount"
      ></el-table-column>

      <el-table-column
        label="冻结积分"
        align="center"
        prop="lock_amount"
      ></el-table-column>
      <el-table-column
        label="总积分"
        align="center"
        prop="total_amount"
      ></el-table-column>
      <!--      <el-table-column label="状态" align="center" prop="status">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ scope.row.status === 1 ? "可用" : "冻结" }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="checkIntegralLogVisible(true, scope.row)"
            >查询积分流水</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    账户流水-->
    <el-dialog
      title="积分流水"
      :visible.sync="integralLogVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-tabs v-model="logActiveName" type="card" @tab-click="checkLogTab">
        <el-tab-pane label="总积分" name="all"></el-tab-pane>
        <el-tab-pane label="可用积分" name="usable"></el-tab-pane>
        <el-tab-pane label="冻结积分" name="frozen"></el-tab-pane>
      </el-tabs>
      <el-table
        :data="integralLogList"
        border
        style="width: 100%"
        v-loading="logListLoading"
        element-loading-text="Loading"
        size="small"
      >
        <el-table-column prop="id" label="编号" width="80"> </el-table-column>
        <el-table-column prop="amount" label="变更金额" width="80">
        </el-table-column>
        <el-table-column prop="biz_type" label="业务类型">
          <template slot-scope="scope">
            <span>{{ getValue(BIZ_TYPE, scope.row.biz_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="new_amount" label="变更后得金额">
        </el-table-column>
        <el-table-column prop="old_amount" label="变更前得金额">
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column align="center" prop="created_at" label="创建时间">
          <template slot-scope="scope">
            <i class="el-icon-time" />
            <span>{{ formatApiDate(scope.row.created_at) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="created_at" label="修改时间">
          <template slot-scope="scope">
            <i class="el-icon-time" />
            <span>{{ formatApiDate(scope.row.updated_at) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px;text-align: right;">
        <el-pagination
          :current-page="logListQuery.page"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="logListQuery.limit"
          :total="logTotal"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="logHandleSizeChange"
          @current-change="logHandleCurrentChange"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkIntegralLogVisible(false, {})"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatApiDate } from "@/utils/utils";
import { BIZ_TYPE, getValue } from "@/utils/enumUtile";
import { getUserIntegralList, getUserIntegralLogList } from "@/api/financial";

export default {
  computed: {
    BIZ_TYPE() {
      return BIZ_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: "", // 系统支付订单号
        status: "" //状态:1-正常，2-冻结
      },
      integralLogVisible: false, // 查看积分流水
      integralLogObj: false, // 要查看积分流水
      integralLogList: [], // 积分流水
      logActiveName: "all", //
      logListQuery: {
        page: 1,
        limit: 10
      },
      logTotal: 0,
      logListLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    logHandleSizeChange(val) {
      this.logListQuery.limit = val;
      this.getUserIntegralLogList();
    },
    logHandleCurrentChange(val) {
      this.logListQuery.page = val;
      this.getUserIntegralLogList();
    },
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getUserIntegralList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 积分流水
     * @param status
     * @param editObj
     */
    checkIntegralLogVisible(status, editObj = {}) {
      this.integralLogObj = editObj;
      if (status) {
        this.getUserIntegralLogList();
      } else {
        this.integralLogList = [];
        this.logActiveName = "all"; //
        this.logListQuery = {
          page: 1,
          limit: 10
        };
        this.logTotal = 0;
        this.logListLoading = false;
      }
      this.integralLogVisible = status;
    },

    checkLogTab() {
      this.logListQuery = {
        page: 1,
        limit: 10
      };
      this.logTotal = 0;
      this.integralLogList = [];
      this.getUserIntegralLogList();
    },
    /**
     * 查询积分流水
     */
    getUserIntegralLogList() {
      this.logListLoading = true;
      const integralLogObj = this.integralLogObj;
      const params = {
        ...this.logListQuery,
        accountId: integralLogObj.id, // 账户ID
        type: "" //类型：1-总余额，2-可用余额，3-冻结余额
      };
      const logActiveName = this.logActiveName;
      if (logActiveName === "all") {
        params.type = 1;
      } else if (logActiveName === "usable") {
        params.type = 2;
      } else if (logActiveName === "frozen") {
        params.type = 3;
      }
      getUserIntegralLogList(params)
        .then(resp => {
          console.log("resp", resp);
          this.integralLogList = resp.data.data;
          this.logTotal = resp.data.total;
        })
        .finally(() => {
          this.logListLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss"></style>
